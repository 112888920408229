<template>
<div>
	 	
 	<v-overlay :value="overlay" absolute>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    
	<v-toolbar flat color="white">
                    <v-toolbar-title>Transferencias</v-toolbar-title>
                    <v-divider
                        class="mx-4"
                        inset
                        vertical
                    >
                    </v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Buscar por cotizacion o cliente"
                        single-line
                        id="searchBox"
                        class='mt-3'
                        @click:append=cargarListaTransferencias
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    <v-btn 
                        small
                        color = 'primary' 
                        @click = 'nuevaTransferencia'
                    > 
                        <v-icon>mdi-plus-circle-outline</v-icon> Nueva Transferencia
                    </v-btn>
                </v-toolbar>
               
	 	<v-data-table
            :items="dataTable"
			:headers= "headers"                        
            :options.sync="options"
            :loading="loadingTable"
            loading-text="Cargando registros..."
            item-key="transferenciaId"
            class="elevation-1"
            dense >
        </v-data-table>
        
	 
	 <v-dialog 
            v-model="dialogTransferencia"
            
            
            max-width='900px' >
           
            <v-card>
            	  <v-card-title>
		          <span class="headline">Crear Transferencia</span>
		        </v-card-title>
		        <v-card-text>
          		<v-container>
          		
          		<v-row>
          			<v-form ref='formTransferencia' >
          			
          			<v-row >
                        <v-col 
                            cols="12" 
                            
                        >
                       
                         <v-select
					          v-model="selBodegaOrigen"
					          :hint="`${selBodegaOrigen.facility_id}, ${selBodegaOrigen.fac_name}`"
					          :items="itemsBodegaOrigen"
					          item-text="fac_name"
					          item-value="facility_id"
					          label="Origen"
					          dense
					        ></v-select>
                         
                        </v-col>
                       </v-row>
                       
                       <v-row >
                        <v-col 
                            cols="12" 
                             
                        >
                        
                            <v-select
					          v-model="selBodegaDest"
					          :hint="`${selBodegaDest.facility_id}, ${selBodegaDest.fac_name}`"
					          :items="itemsBodegaDest"
					          item-text="fac_name"
					          item-value="facility_id"
					          label="Destino"
					          dense
					           
					        ></v-select>
                        </v-col>
                    </v-row>
                    
                    <v-row>
                    	<v-col>
                    	 <v-btn
                	small
                	color='primary'
                	dark
                	@click = "agregarItem"
                >
                	Agregar
                </v-btn>
                    		<v-data-table
                    		dense	                        
	                        :headers="headersItemsTrans"
	                        :items="itemsTransferencia"
	                       
	                        
	                        loading-text="Cargado datos"
	                        :loading=loadingTableTransfItems
	                        class="elevation-1"
	                        
	                        show-select
	                        :single-select=true
                    		>
                    		</v-data-table>
                    	</v-col>
                    </v-row>
                    
                    
                     <v-row>
                     <v-col 
                            cols="12" 
                            
                        >
                     <v-btn 
                        small
                        color = 'primary' 
                        @click = 'guardarTransferencia'
                    > 
                        <v-icon>mdi-plus-circle-outline</v-icon> Guardar
                    </v-btn>
                    </v-col>
                    </v-row>
                </v-form>
                	
               </v-row>
               
                  
                
                </v-container>
          		</v-card-text>
            </v-card>
            
        </v-dialog>
	         
</div>


</template>


<script>
import {mapState, mapMutations, mapActions} from 'vuex'

export default {
    name:'ListaTransferenciasComponent',
    components:{
        
    },
    data: ()=> ({
    	
    	headers:[
            {text: 'ID', align: 'start', value: 'tinv_id', width : 80},
            {text: 'Fecha', align: 'start', value: 'fecha'},
            {text: 'Origen', align: 'start', value: 'borigen'},
            {text: 'Destino', align: 'start', value: 'bdestino'},
            {text: 'Estado', align: 'start', value: 'estado_id'},
            {text: 'Creado por', align: 'start', value: 'cotizadoPor'},
            
        ],
    	 dataTable: [],
    	 options: {},
    	 overlay: false,
    	 search: "",
    	 dialogTransferencia: false,
    	 headersItemsTrans: [
    		 {text: 'ID', align: 'start', value: 'id', width : 80},
             {text: 'Product Id', align: 'start', value: 'product_id'},
             {text: 'Nombre', align: 'start', value: 'internal_name'},
             {text: 'Cantidad', align: 'start', value: 'cantidad'},
    		 
    	 ],
    	 itemsTransferencia: [],
    	 
    	 
    	 selBodegaOrigen:  {facility_id : '10000',fac_name: 'BODEGA QUITO'},
    	 itemsBodegaOrigen: [{facility_id:'10000',fac_name : 'BODEGA QUITO'},
    		 			     {facility_id:'10030',fac_name : 'BODEGA GUAYAQUIL'}
    		 ],
    		 
    	 selBodegaDest:  {facility_id : '10000',fac_name: 'BODEGA QUITO'},
         itemsBodegaDest: [{facility_id:'10000',fac_name : 'BODEGA QUITO'},
        		 			     {facility_id:'10030',fac_name : 'BODEGA GUAYAQUIL'}
        		 ]
    	 
    }),
    computed: {
    	...mapState('master',['loadingTable']),
    	...mapState('bodega',['menu'])
    }
   	,methods: {
	   	 ...mapMutations('master',['setUrl','setOverlay','setMenu','setLoadingTable']),
	   	 
	     ...mapActions('master',['requestApi']),
   		
   		cargarListaTransferencias(){
            this.dataTable=[]
            
            this.setLoadingTable(true)
            
            this.setUrl('lista-transferencias')
            this.requestApi({
                method : 'GET',
                data :{
                    
                }
            }).then(res=>{
                

                let data =  res.data.lista_transferencias
                this.dataTable = data
                this.setLoadingTable(false)
            
            })
        },
        agregarItem() {
        	this.itemsTransferencia.push( {"id":"1", "product_id":"p1","internal_name":"asdfafasf","cantidad":"2"})	
        },
        
        nuevaTransferencia() {
        	this.dialogTransferencia = true
        	//cargar datos
        	  this.setUrl('cargar-transferencia')
	            this.requestApi({
	                method : 'GET',
	                data :{
	                    
	                }
	            }).then(res=>{
	                
	
	                let data =  res.data.lista_transferencias
	                this.dataTable = data
	                this.setLoadingTable(false)
	            
	            })
        }
        
        ,
        guardarTransferencia() {
        	this.setUrl('cargar-transferencia')
            this.requestApi({
                method : 'POST',
                data :{
                    
                }
            }).then(res=>{
                

                /*let data =  res.data.lista_transferencias
                this.dataTable = data
                this.setLoadingTable(false)*/
            
            })
        }
   	},
   	
    watch: {
    	
    	options: {
            handler () {
                this.cargarListaTransferencias()
            },
            deep: true,
        },
    },
    mounted(){
        this.setMenu(this.menu)
    }
    
    
}
</script>